import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  RiUserStarLine,
  RiUserSearchLine,
  RiUserSettingsLine,
  // RiFolderReceivedLine,
  RiFileUserFill,
} from "react-icons/ri";
import { MdAssessment, MdBusinessCenter } from "react-icons/md";
import { IoIosAnalytics } from "react-icons/io";
import { FaUserGraduate, FaUserLock, 
// FaUsers 
} from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import { GoDashboard, 
// GoFileDirectory 
} from "react-icons/go";
import { BsCalendarDate } from "react-icons/bs";
import {
  // FaRobot,
  // FaUserSecret,
  FaTools,
  // FaMoneyCheckAlt,
} from "react-icons/fa";
// import { BiMessageRoundedDetail } from "react-icons/bi";
import { setFilterFor, setFilters, setSearch } from "../../appRedux/actions";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const dispatch = useDispatch();
  const { authUser, newUser } = useSelector(({ auth }) => auth);
  const pathname = useSelector(({ settings }) => settings.pathname);
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  let currentUserRole = "";
  let currentToken = localStorage.getItem("current_token");
  currentUserRole = currentToken ? newUser?.roles : authUser?.roles;
  // const checkRole = (role) => {
  //   return (
  //     role &&
  //     role.includes("engineer") &&
  //     (role.includes("scrum_master") ||
  //       role.includes("admin") ||
  //       role.includes("superadmin"))
  //   );
  // };
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
    >
      <Menu.Item key="dashboard">
        <Link to="/dashboard">
          <GoDashboard style={{ fontSize: "18px", marginRight: "5px" }} />
          <IntlMessages id="sidebar.dashboard" />
        </Link>
      </Menu.Item>
      <SubMenu
        key="marketing"
        title={
          <div>
            <MdBusinessCenter
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.marketing" />
          </div>
        }
      >
        <Menu.Item key="lead">
          <Link
            to="/marketing/lead"
            onClick={() => {
              dispatch(setFilters({}));
              dispatch(setSearch(""));
              dispatch(setFilterFor("all"));
            }}
          >
            <RiUserSearchLine
              style={{ fontSize: "18px", marginRight: "5px" }}
            />

            <IntlMessages id="sidebar.marketing.requirements" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="submission"
          onClick={() => {
            dispatch(setFilters({}));
            dispatch(setSearch(""));
            dispatch(setFilterFor("all"));
          }}
        >
          <Link to="/marketing/submission">
            {" "}
            <RiUserStarLine style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.submissions" />
          </Link>
        </Menu.Item>
        <Menu.Item key="test">
          <Link
            to="/marketing/test"
            onClick={() => {
              dispatch(setFilters({}));
              dispatch(setSearch(""));
              dispatch(setFilterFor("all"));
            }}
          >
            <MdAssessment style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.tests" />
          </Link>
        </Menu.Item>
        <Menu.Item key="interview">
          <Link
            to="/marketing/interview"
            onClick={() => {
              dispatch(setFilters({}));
              dispatch(setSearch(""));
              dispatch(setFilterFor("all"));
            }}
          >
            <AiOutlineSchedule
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.marketing.interviews" />
          </Link>
        </Menu.Item>
        <Menu.Item key="po">
          <Link
            to="/marketing/po"
            onClick={() => {
              dispatch(setFilters({}));
              dispatch(setSearch(""));
              dispatch(setFilterFor("all"));
            }}
          >
            <FaUserLock style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.pos" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="profiles"
          onClick={() => {
            dispatch(setFilters({}));
            dispatch(setSearch(""));
          }}
        >
          <Link to="/marketing/profiles">
            <FaUserGraduate style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.profiles" />
          </Link>
        </Menu.Item>
        <Menu.Item
          key="reports"
          onClick={() => {
            dispatch(setFilters({}));
            dispatch(setSearch(""));
            dispatch(setFilterFor("all"));
          }}
        >
          <Link to="/marketing/reports">
            <IoIosAnalytics style={{ fontSize: "18px", marginRight: "5px" }} />
            <IntlMessages id="sidebar.marketing.reports" />
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="bench">
        <Link
          to="/consultant/bench"
          onClick={() => {
            dispatch(setFilters({}));
            dispatch(setSearch(""));
            dispatch(setFilterFor("all"));
          }}
        >
          <RiUserSettingsLine
            style={{ fontSize: "18px", marginRight: "5px" }}
          />
          <IntlMessages id="sidebar.consultant" />
        </Link>
      </Menu.Item>
      {/* {currentUserRole &&
        (currentUserRole.includes("engineer") ||
          currentUserRole.includes("superadmin")) && (
          <SubMenu
            key="engineering"
            title={
              <div>
                <FaRobot style={{ fontSize: "18px", marginRight: "5px" }} />
                <IntlMessages id="sidebar.engineering" />
              </div>
            }
          >
            <Menu.Item key="projects">
              <Link
                to="/engineering_module"
                onClick={() => {
                  dispatch(setFilters({}));
                  dispatch(setSearch(""));
                  dispatch(setFilterFor("all"));
                }}
              >
                <FaRobot style={{ fontSize: "18px", marginRight: "5px" }} />
                Projects
              </Link>
            </Menu.Item>
            <Menu.Item key="eng_reports">
              <Link to="/engineering">
                <IoIosAnalytics
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                Reports
              </Link>
            </Menu.Item>
            {checkRole(currentUserRole) && (
              <Menu.Item key="engineering_structure">
                <Link to="/engineering_report">
                  <FaUserSecret
                    style={{ fontSize: "18px", marginRight: "5px" }}
                  />
                  Engineers Detail
                </Link>
              </Menu.Item>
            )}
            {checkRole(currentUserRole) && (
              <Menu.Item key="eng_team">
                <Link to="/engineering_team">
                  <FaUsers style={{ fontSize: "18px", marginRight: "5px" }} />
                  Teams Detail
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )} */}
      {/*{*/}
      {/*  (currentUserRole.includes("engineer") || currentUserRole.includes("superadmin")) &&*/}
      {/*  <Menu.Item key="engineering">*/}
      {/*    <Link to="/engineering">*/}
      {/*      <FaRobot style={{fontSize: '18px', marginRight: '5px'}}/>*/}

      {/*      <IntlMessages id="sidebar.engineering"/></Link>*/}
      {/*  </Menu.Item>*/}
      {/*}*/}
      {/*{*/}
      {/*  (currentUserRole.includes("engineer") || currentUserRole.includes("superadmin")) &&*/}
      {/*  <Menu.Item key="engineering_module">*/}
      {/*    <Link to="/engineering_module" onClick={()=> {*/}
      {/*      dispatch(setFilters({}))*/}
      {/*      dispatch(setSearch(""))*/}
      {/*      dispatch(setFilterFor("all"))*/}
      {/*    }}>*/}
      {/*      <FaRobot style={{fontSize: '18px', marginRight: '5px'}}/>*/}

      {/*      Engineering Module</Link>*/}
      {/*  </Menu.Item>*/}
      {/*}*/}
      {/* {currentUserRole &&
        (currentUserRole.includes("finance") ||
          currentUserRole.includes("superadmin")) && (
          <SubMenu
            key="finance"
            title={
              <div>
                <FaMoneyCheckAlt
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                <IntlMessages id="sidebar.finance" />
              </div>
            }
          >
            <Menu.Item key="finance">
              <Link to="/finance">
                <FaMoneyCheckAlt
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                <IntlMessages id="sidebar.finance" />
              </Link>
            </Menu.Item>
            <Menu.Item key="leave_managment">
              <Link to="/finance/leave_management">
                <FaUserSecret
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                Leave Managment
              </Link>
            </Menu.Item>
          </SubMenu>
        )} */}
      {/* {currentUserRole &&
        (currentUserRole.includes("legal") ||
          currentUserRole.includes("superadmin")) && (
          <Menu.Item key="legal">
            <Link
              to="/legal"
              onClick={() => {
                dispatch(setSearch(""));
                dispatch(setFilterFor("my"));
              }}
            >
              <FaUserSecret style={{ fontSize: "18px", marginRight: "5px" }} />

              <IntlMessages id="sidebar.legal" />
            </Link>
          </Menu.Item>
        )} */}
      <SubMenu
        key="utility"
        title={
          <div>
            <FaTools style={{ fontSize: "18px", marginRight: "5px" }} />

            <IntlMessages id="sidebar.utility" />
          </div>
        }
      >
        <Menu.Item key="calendar">
          <Link to="/utility/calendar">
            <BsCalendarDate
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="Calendar" />
          </Link>
        </Menu.Item>

        {/* <Menu.Item key="messaging">
          <Link to="/utility/messaging">
            <BiMessageRoundedDetail
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.utility.messaging" />
          </Link>
        </Menu.Item>
        <Menu.Item key="Assets">
          <Link to="/utility/assets">
            <GoFileDirectory style={{ fontSize: "18px", marginRight: "5px" }} />

            <IntlMessages id="sidebar.utility.assets" />
          </Link>
        </Menu.Item>
        <Menu.Item key="oldsubmission">
          <Link to="/utility/oldsubmission">
            <RiFolderReceivedLine
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            <IntlMessages id="sidebar.utility.old_submissions" />
          </Link>
        </Menu.Item> */}
        {currentUserRole && currentUserRole.includes("superadmin") && (
          <Menu.Item key="handover">
            <Link to="/utility/userDirectory">
              <RiFileUserFill
                style={{ fontSize: "18px", marginRight: "5px" }}
              />
              <IntlMessages id="sidebar.utility.userDirectory" />
            </Link>
          </Menu.Item>
        )}
      </SubMenu>
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default React.memo(HorizontalNav);
