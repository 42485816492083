import React, { Component } from "react";
import { Input, Button, Form, Select, Spin } from "antd";
import axiosJSON from "../util/Api";
import { messageError, messageSuccess } from "../util/AlertMessage";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_id: null,
      email: "",
      c_password: "",
      phone: "",
      name: "",
      gender: "male",
      team: "",
      password: "",
      teamList: [],
      team_status: null,
      formErrors: { employee_id: "", email: "", password: "" },
      emailValid: false,
      passwordValid: false,
      employee_idValid: false,
      error: "",
      roleList: [],
      value: [],
      role: [],
      fetching: false,
      spinLoading: false,
    };
  }

  componentDidMount() {
    this.getTeamList();
    this.getRole();
  }

  // validateField = (fieldName, value) => {
  //   let fieldValidationErrors = this.state.formErrors;
  //   let emailValid = this.state.emailValid;
  //   let employee_IDValid = this.state.employee_IDValid;
  //   let passwordValid = this.state.passwordValid;

  //   switch (fieldName) {
  //     case "email":
  //       var re = /\S+@\S+\.\S+/;
  //       if (re.test(String(value).toLowerCase())) {
  //         emailValid = true;
  //       } else {
  //         emailValid = false;
  //       }
  //       fieldValidationErrors.email = emailValid ? "" : "is not valid";
  //       break;
  //     case "employee_id":
  //       employee_IDValid = value.match(/[0-9]$/);
  //       fieldValidationErrors.employee_id = employee_IDValid
  //         ? ""
  //         : " is invalid";
  //       break;
  //     case "password":
  //       passwordValid = value.length >= 6;
  //       fieldValidationErrors.password = passwordValid ? "" : " is too short";
  //       break;
  //     default:
  //       break;
  //   }

  //   this.setState(
  //     {
  //       formErrors: fieldValidationErrors,
  //       emailValid: emailValid,
  //       passwordValid: passwordValid,
  //       employee_idValid: employee_IDValid,
  //     },
  //     this.validateForm
  //   );
  // };

  getTeamList = () => {
    axiosJSON
      .get("/api/employee/team/?query=all")
      .then((data) => {
        data.data.data.map((item, i) => this.state.teamList.push(item));
        this.setState({ team_status: data.status });
      })
      .catch(function (error) {
        messageError(error);
      });
  };

  // handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   this.setState({ [name]: value }, () => {
  //     this.validateField(name, value);
  //   });
  // };

  // handleInputChangeTeam = (team) => {
  //   this.setState({
  //     team: team,
  //   });
  // };

  getRole = () => {
    axiosJSON
      .get("/api/employee/role/")
      .then((data) => {
        this.setState({ roleList: data.data.data });
      })
      .catch(function (error) {
        messageError(error);
      });
  };

  addUser = (values) => {
    this.setState({ spinLoading: true });
    console.log(values);
    const body = {
      employee_id: values.employee_id,
      name: values.name,
      role: values.role,
      phone: values.phone,
      email: values.email,
      team: values.team,
      gender: values.gender,
      password: values.password,
    };

    axiosJSON
      .post("/api/employee/", body)
      .then((data) => {
        this.setState({ spinLoading: false });
        messageSuccess("User Added Successfully.");
        this.props.handleClose();
      })
      .catch((e) => {
        this.setState({ spinLoading: false });
        messageError(e);
      });
  };

  // handleChangeSelect = (value) => {
  //   this.setState({
  //     value,
  //     data: [],
  //     fetching: false,
  //   });
  // };

  // onGenderChange = (value) => {
  //   this.setState({ gender: value });
  // };

  // errorClass = (error) => {
  //   return error.length === 0 ? "" : "has-error";
  // };

  render() {
    const { roleList, teamList } = this.state;
    return (
      <div className="whitecontainer" style={{ height: "auto" }}>
        <Spin spinning={this.state.spinLoading}>
          <Form name="basic" autoComplete="off" onFinish={this.addUser}>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="profileformnew2">
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Name" id="outlined-required" />
                  </Form.Item>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select Gender",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Gender"
                    >
                      <Select.Option key="male" value="male">
                        Male
                      </Select.Option>
                      <Select.Option key="female" value="female">
                        Female
                      </Select.Option>
                      <Select.Option key="other" value="other">
                        Other
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input Email",
                      },
                      {
                        type: "email",
                        message: "Please enter valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email" id="outlined-required" />
                  </Form.Item>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                      {
                        required: true,
                        message: "Please select Role",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select role"
                      notFoundContent={null}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {roleList.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name.charAt(0).toUpperCase() + d.name.slice(1)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="profileformnew2">
                  <Form.Item
                    name="employee_id"
                    label="Employee Id"
                    rules={[
                      {
                        required: true,
                        message: "Please input Employee Id",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Employee Id"
                      id="outlined-required"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input Password",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter Password"
                      id="outlined-required"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input Phone",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Phone"
                      id="outlined-required"
                    />
                  </Form.Item>
                  <Form.Item
                    name="team"
                    label="Team"
                    rules={[
                      {
                        required: true,
                        message: "Please select Team",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      notFoundContent={null}
                      style={{ width: "100%" }}
                      placeholder="Team List"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {teamList.map((item, i) => (
                        <Select.Option key={i} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <Form.Item>
                <Button
                  type="default"
                  htmlType="submit"
                  className="submit-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default AddUser;
