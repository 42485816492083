import React from "react";
import { Tag, Collapse, Radio } from "antd";
import { phoneNumberFormatter } from "../../util/dataParser";
import { CaretRightOutlined } from "@ant-design/icons";
import CertificationData from "./certificationData";

const displayProfile = (props) => {
  const { Panel } = Collapse;
  const profileData = { ...props.profileData };

  const getCurrentproject = (current_project) => {
    let allProject = "";
    let count = 0;
    current_project.map(({ id, name }) => {
      allProject += ` ${name}`;
      count += 1;
      if (current_project.length !== count) {
        allProject += ",";
      }
      return null;
    });
    return allProject;
  };

  const getShiftDisplayName = (shift) => {
    let shiftDisplay = "";
    props.shiftList.map((ele) => {
      if (shift === ele.value) {
        shiftDisplay = ele.display;
      }
      return null;
    });
    return shiftDisplay;
  };

  const updateMarkCertificates = (value) => {
    return (
      <div>
        <p>Did you have any certification?</p>
        <Radio.Group
          value={value}
          onChange={(e) => props.onCertificateAdd(e.target.value)}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </div>
    );
  };

  return (
    <div className="tabular_ana">
      <div className="accordian_styles">
        <Collapse
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header="Personal Details" key="1">
            <div className={"row pb-3"}>
              <div className={"col-12 pt-1"}>
                <div className="head1">Email Id</div>
                <div className="subhead1">{profileData.email}</div>
              </div>

              <div className={"col-12 pt-3"}>
                <div className="head1">Contact Number</div>
                <div className="subhead1">
                  {phoneNumberFormatter(profileData.phone)}
                </div>
              </div>
              <div className={"col-12 pt-3"}>
                <div className="head1">Gender</div>
                <div className="subhead1">
                  {profileData.gender
                    ? profileData.gender[0].toUpperCase() +
                      profileData.gender.substring(1)
                    : "NA"}
                </div>
              </div>
              <div className={"col-12 pt-3"}>
                <div className="head1">Shift Details</div>
                <div className="subhead1">
                  {profileData.shift
                    ? getShiftDisplayName(profileData.shift)
                    : "NA"}
                </div>
              </div>
            </div>
          </Panel>

          <Panel header="Professional Details" key="2">
            <div className={"row pb-3"}>
              <div className={"col-12 pt-1"}>
                <div className="head1">Department</div>
                <div className="subhead1">
                  {profileData.team && profileData.team.department
                    ? profileData.team.department
                    : "NA"}
                </div>
              </div>
              <div className={"col-12 pt-3"}>
                <div className="head1">Team</div>
                <div className="subhead1">
                  {profileData.team ? profileData.team.name : "NA"}
                </div>
              </div>
              <div className={"col-12 pt-3"}>
                <div className="head1 pb-1">Role</div>
                <div className="subhead1">
                  {profileData.display_roles
                    ? profileData.display_roles.map((role, i) => (
                        <Tag className="tag_edit" key={i}>
                          {role}
                        </Tag>
                      ))
                    : "NA"}
                </div>
              </div>
              {/* {profileData.roles && profileData.roles.indexOf("engineer") > -1 && ( */}
              <>
                {" "}
                <div className={"col-12 pt-3"}>
                  <div className="head1 pb-1">Skill Set</div>
                  <div className="subhead1">
                    {profileData.technology
                      ? profileData.technology.map((technology, i) => (
                          <Tag className="tag_edit" key={i}>
                            {technology[0].toUpperCase() +
                              technology.substring(1)}
                          </Tag>
                        ))
                      : "NA"}
                  </div>
                </div>
                <div className={"col-12 pt-3"}>
                  <div className="head1">Current project</div>
                  <div className="subhead1">
                    {profileData.project
                      ? getCurrentproject(profileData.project.current_project)
                      : "Not Available"}
                  </div>
                </div>
                <div className={"col-12 pt-3"}>
                  <div className="head1">Total Supported Projects</div>
                  <div className="subhead1">
                    <Tag color="#87d068">
                      {profileData.project ? profileData.project.total : "0"}
                    </Tag>
                    {profileData.project && profileData.project.total ? (
                      <span
                        onClick={props.onRevert}
                        style={{ cursor: "pointer" }}
                      >
                        show projects
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
              {/* )} */}
            </div>
          </Panel>
          {profileData.have_certificate === null ||
          profileData.have_certificate === false ? (
            <>{updateMarkCertificates(profileData.have_certificate)}</>
          ) : (
            <Panel
              header="Certifications"
              key="3"
              onClick={() => {}}
              extra={props.genExtra()}
            >
              <div>
                {props.certificatesList && props.certificatesList.length > 0 ? (
                  <CertificationData
                    certificatesList={props.certificatesList}
                    onCertificateDelete={props.onCertificateDelete}
                    onCertificateEdit={props.onCertificateEdit}
                  />
                ) : (
                  <>{updateMarkCertificates(profileData.have_certificate)}</>
                )}
              </div>
            </Panel>
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default displayProfile;
