import React, { useEffect, useState } from "react";
import { Button, Layout, Modal } from "antd";
import TopbarHeader from "../Topbar/HorizontalNav";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
// import {footerText} from "util/config";
import App from "routes/index";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import {
  myDetails,
  unreadNotify,
  impersonatedMeDetails,
} from "../../appRedux/actions";
import firebase from "firebase";
import axiosJSON from "../../util/Api";
import EditProfile from "../../modals/editProfile";
import { messageSuccess, messageWarning } from "../../util/AlertMessage";

const {
  Content,
  // Footer
} = Layout;

const MainApp = (props) => {
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const match = useRouteMatch();
  const messaging = firebase.messaging();

  messaging.onMessage = (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };

    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = function (event) {
        event.preventDefault();
        // window.open(payload.notification.click_action , '_blank');
        notification.close();
      };
    }
  };
  useEffect(() => {
    if (Notification.permission === "granted") {
      messaging.getToken().then((t) => {
        updateFCMToken(t);
      });
    } else if (Notification.permission === "default") {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return messaging.getToken();
          } else return null;
        })
        .then((token) => {
          updateFCMToken(token);
        })
        .catch((error) => {
          if (error.code === "messaging/permission-blocked") {
            messageWarning(
              "You have blocked the permission for push notifications"
            );
          }
        });
    } else {
      messageWarning("You have blocked the permission for push notifications");
    }
    let currentToken = localStorage.getItem("current_token");
    if (currentToken) {
      if (profileCheck(props.currentAuth)) {
        setIsEditProfile(true);
      }
    } else {
      if (profileCheck(props.auth)) {
        setIsEditProfile(true);
      }
    }
  }, [messaging, props.auth, props.currentAuth]);

  const profileCheck = (user) => {
    if (user) {
      if (user.shift) {
        if (
          user.roles.indexOf("engineer") > -1 ||
          user.roles.indexOf("delivery") > -1
        ) {
          if (user.technology && user.technology.length > 0) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
      return true;
    }
  };

  const updateFCMToken = (token) => {
    const body = {
      fcm_token: token,
    };
    axiosJSON
      .post("/api/fcm/", body)
      .then(({ data, status }) => {
        messageSuccess("Your system can receive notifications now!");
      })
      .catch(function (e) {
        console.log("Error:", e.error);
      });
  };

  const dispatch = useDispatch();
  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  const getUnreadCount = (token) => {
    dispatch(unreadNotify(token));
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return (
          <Topbar
            authInfo={props.currentAuth ? props.currentAuth : props.auth}
          />
        );
      case NAV_STYLE_DRAWER:
        return (
          <Topbar
            authInfo={props.currentAuth ? props.currentAuth : props.auth}
          />
        );
      case NAV_STYLE_MINI_SIDEBAR:
        return (
          <Topbar
            authInfo={props.currentAuth ? props.currentAuth : props.auth}
          />
        );
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };
  const getTopbar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <TopbarHeader />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <TopbarHeader />;
      case NAV_STYLE_DRAWER:
        return <TopbarHeader />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <TopbarHeader />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <TopbarHeader />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <TopbarHeader />;
      default:
        return null;
    }
  };

  // const getSidebar = (navStyle, width) => {
  //   if (width < TAB_SIZE) {
  //     return <TopbarHeader/>;
  //   }
  //   switch (navStyle) {
  //     case NAV_STYLE_FIXED :
  //       return <Sidebar/>;
  //     case NAV_STYLE_DRAWER :
  //       return <Sidebar/>;
  //     case NAV_STYLE_MINI_SIDEBAR :
  //       return <Sidebar/>;
  //     case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
  //       return <Sidebar/>;
  //     case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
  //       return <Sidebar/>;
  //     default :
  //       return null;
  //   }
  // };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let currentToken = localStorage.getItem("current_token");
    if (currentToken) {
      dispatch(impersonatedMeDetails(currentToken));
      getUnreadCount(currentToken);
      if (profileCheck(props.currentAuth)) {
        setIsEditProfile(true);
      }
    } else {
      getUnreadCount(token);
      if (profileCheck(props.auth)) {
        setIsEditProfile(true);
      }
    }
    dispatch(myDetails(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProfileButtonClick = () => {
    setIsEditProfile(false);
    setEditProfile(true);
  };
  // const getProfile = () => {
  //   let currentToken = localStorage.getItem("current_token");
  //   if(currentToken)
  //     return props.currentAuth
  //   else
  //     return props.auth
  // }
  const onRevrt = (link) => {
    setEditProfile(false);
    window.location.href = link;
  };

  const handleClose = () => {
    setEditProfile(false);
  };

  return (
    <Layout className="gx-app-layout">
      <Layout>
        {getNavStyles(navStyle)}
        {getTopbar(navStyle, width)}
        <Content
          className={`gx-layout-content ${getContainerClass(navStyle)} `}
        >
          <App match={match} />
          {/*<Footer style={{marginTop: '0px'}}>*/}
          {/*  <div className="gx-layout-footer-content" style={{textAlign: 'center'}}>*/}
          {/*    {footerText}*/}
          {/*  </div>*/}
          {/*</Footer>*/}
          {isEditProfile && (
            <Modal
              title="Update Profile"
              visible={isEditProfile}
              footer={null}
              closable={false}
            >
              <div>
                <div className="my-3">
                  <span>
                    {
                      "Please add your respective details in Log1 by going to Edit Profile section"
                    }
                  </span>
                </div>
                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                  <Button type="default" onClick={onProfileButtonClick}>
                    Visit Profile
                  </Button>
                </div>
              </div>
            </Modal>
          )}
          {editProfile && (
            <Modal
              title="Profile"
              onCancel={() => setEditProfile(false)}
              visible={editProfile}
              footer={null}
              className="edit_model"
            >
              <EditProfile handleClose={handleClose} onRevert={onRevrt} />
            </Modal>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  currentAuth: state.auth.newUser,
});

export default connect(mapStateToProps)(MainApp);
