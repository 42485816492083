import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import IntlMessages from "util/IntlMessages";
import axiosJSON from "../util/Api";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { messageError, messageSuccess } from "../util/AlertMessage";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const token = useSelector(({ auth }) => auth.token);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push("/dashboard");
    }
  }, [token, props.history]);

  const onFinish = (values) => {
    setLoading(true);
    axiosJSON
      .post("api/password/token_request/", values)
      .then(({ data, status }) => {
        if (status === 200) {
          messageSuccess(data.message);
          setLoading(false);
          localStorage.setItem("resetMessage", data.message);
          history.push("/reset-password");
        }
      })
      .catch(function (error) {
        if (error.status === 400) {
          setLoading(false);
          messageError(error.data.message);
        } else {
          setLoading(false);
          messageError(error.data.message);
        }
      });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div
          className="gx-login-header"
          style={{
            background: "#24305e",
            borderRadius: "5px",
            margin: "-10px",
          }}
        >
          <img
            src={require("assets/images/logo-white.png")}
            alt="log1"
            title="log1"
          />
        </div>
        <br />
        <div className="gx-mb-4 gx-mt-4">
          <h2>Recovery Message</h2>
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            <IntlMessages id="app.userAuth.forgot" />
          </p>
        </div>
        <Spin spinning={loading}>
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              name="email"
              rules={[{ required: true, message: "Please input your E-mail!" }]}
            >
              <Input
                className="gx-input-lineheight"
                type="email"
                placeholder="E-Mail Address"
              />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                <IntlMessages id="app.userAuth.send" />
              </Button>
            </FormItem>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ForgotPassword;
