import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";

const ChangePassword = (props) => {
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    const validateField = () => {
      let isValid = passwordValid;
      if (password.length === c_password.length && password === c_password) {
        isValid = true;
      } else {
        isValid = false;
      }
      if (!isValid) {
        setFormError("Password Does not Match");
      } else {
        setFormError("");
      }
      setPasswordValid(isValid);
    };
    validateField();
  }, [password, c_password, passwordValid]);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "c_password") {
      setConfirmPassword(e.target.value);
    }
  };

  return (
    <div className="row">
      <div className="col-md-6 col-sm-6 col-xs-12">
        <div className="profilesection pb-3">
          <div className="pt-3">
            <div className="head1 mb-1"> Current Password: </div>
            <div className="subhead1">
              <Input.Password
                name="current_password"
                type="password"
                value={current_password}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="pt-3">
            <div className="head1 mb-1"> Change Password:</div>
            <div className="subhead1">
              <Input.Password
                name="password"
                type="password"
                value={password}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="pt-3">
            <div className="head1 mb-1"> Confirm Password: </div>
            <div className="subhead1">
              <Input.Password
                name="c_password"
                type="password"
                value={c_password}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {formError.length > 0 ? (
              <span style={{ color: "red" }}>{formError}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="pt-3">
          <Button
            disabled={
              !passwordValid ||
              password.length <= 0 ||
              current_password.length <= 0
            }
            onClick={() => props.changePassword(current_password, password)}
          >
            Submit
          </Button>
          <Button onClick={props.onCancel}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
