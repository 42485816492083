import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => {
  return (
    <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}notification`} component={asyncComponent(() => import('./Notification'))}/>
      {/* <Route path={`${match.url}legal`} component={asyncComponent(() => import('./Legal'))}/>
      <Route path={`${match.url}finance/leave_management`} component={asyncComponent(() => import('./FinanceList'))}/>
      <Route path={`${match.url}finance/details/`} component={asyncComponent(() => import('./FinanceDetailView'))}/>
      <Route path={`${match.url}finance/leave/`} component={asyncComponent(() => import('./FinanceDetailView/LeaveBalance'))}/>
      <Route exact={true} path={`${match.url}finance`} component={asyncComponent(() => import('./Finance'))}/> */}
      <Route path={`${match.url}marketing`} component={asyncComponent(() => import('./Marketing'))}/>
      <Route path={`${match.url}utility`} component={asyncComponent(() => import('./Utility'))}/>
      <Route path={`${match.url}consultant`} component={asyncComponent(() => import('./Consultant'))}/>
      {/* <Route path={`${match.url}engineering`} component={asyncComponent(() => import('./Engineering'))}/>
      <Route path={`${match.url}engineering_team`} component={asyncComponent(() => import('./EngineeringTeam'))}/>
      <Route path={`${match.url}team_detail`} component={asyncComponent(() => import('./EngineeringTeam/TeamDetail'))}/>
      <Route path={`${match.url}engineering_module`} component={asyncComponent(() => import('./EngineeingModule'))}/>
      <Route path={`${match.url}engineering_report`} component={asyncComponent(() => import('./EngineeringData/EngineerList'))}/>
      <Route path={`${match.url}project/`} component={asyncComponent(() => import('./EngineeringDetailedView'))}/> */}
      <Route path={`${match.url}myprofile`} component={asyncComponent(() => import('./MyProfile'))}/>
      <Route path={`${match.url}new`} component={asyncComponent(() => import('./CreateLeadForm'))}/>
      <Route path={`${match.url}details`} component={asyncComponent(() => import('./ViewSubmission'))}/>
      <Route path={`${match.url}petition`} component={asyncComponent(() => import('./Legal/Petition'))}/>
      <Route path={`${match.url}timesheet`} component={asyncComponent(() => import('./Finance/Timesheet'))}/>
      <Route path={`${match.url}addConsultant`} component={asyncComponent(() => import('./AddConsultant'))}/>
      {/* <Route path={`${match.url}reportDetail`} component={asyncComponent(() => import('./Engineering/ReportDetails/index'))}/> */}
    </Switch>
  </div>
  );
};

export default App;
