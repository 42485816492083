import {CURRENT_PAGE, CURRENT_SIZE, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, MY,QUERY,FETCHING,UNREAD_COUNTER,FITLERS,SEARCH,FILTER_FOR, ASSIGNMENT_STATUS, LOAD_DATA, HEAD_DATA} from "../../constants/ActionTypes";
import axiosJSON from "../../util/Api";
import {messageError} from "../../util/AlertMessage";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};
export const fetching = () => {
  return {
    type: FETCHING
  }
};

export const toggleMy = (myToggle) => {
  return (dispatch) => {
    dispatch({type: MY, payload: myToggle});
  }
};
export const onSearch = (query) => {
  return (dispatch) => {
    dispatch({type: QUERY, payload: query});
  }
};
export const unreadNotify = (token) => {
  return (dispatch) => {
    axiosJSON.defaults.headers['authorization'] = "Token " + token;
    axiosJSON.get('/api/emp_notify/count/')
      .then(({data, status}) => {
        dispatch({type: UNREAD_COUNTER, payload: data.count});
      }).catch(function (error) {
        messageError(error);
    });

  }
};
export const setFilters = (data) => {
  return (dispatch) => {
    dispatch({type: FITLERS, payload: data});
  }
};
export const setSearch = (data) => {
  return (dispatch) => {
    dispatch({type: SEARCH, payload: data});
  }
};

export const setFilterFor = (data) => {
  return (dispatch) => {
    dispatch({type: FILTER_FOR, payload: data});
  }
};

export const setAssignmentStatus = (data) => {
  return (dispatch) => {
    dispatch({type: ASSIGNMENT_STATUS, payload: data});
  }
}


export const setPageNo = (data) => {
  return (dispatch) => {
    dispatch({type: CURRENT_PAGE, payload: data});
  }
};


export const setPageSize = (data) => {
  return (dispatch) => {
    dispatch({type: CURRENT_SIZE, payload: data});
  }
};


export const setHeaderData = (data) => {
  return (dispatch) => {
    dispatch({type: LOAD_DATA, payload: data});
  }
};

export const setEngineerData = (data) => {
  return (dispatch) => {
    dispatch({type: HEAD_DATA, payload: data});
  }
};





