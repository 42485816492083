import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Collapse,
  Input,
  Radio,
  Checkbox,
  Spin,
  DatePicker,
  Tooltip,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { phoneNumberParser } from "../../util/dataParser";
import {
  PlusOutlined,
  InfoCircleFilled,
  CaretRightOutlined,
} from "@ant-design/icons";
import axiosJSON from "../../util/Api";
import moment from "moment";
import { messageError, messageSuccess } from "../../util/AlertMessage";
// import CertificationData from "./certificationData";

const EditUserProfile = (props) => {
  const { Panel } = Collapse;
  const [technology, setTechnology] = useState(props.selectedTechnology);
  const [name, setName] = useState(props.profile.employee_name);
  const [gender, setGender] = useState(props.profile.gender);
  const [isRemote, setIsRemote] = useState(false);
  const [add, setAdd] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    phoneNumberParser(props.profile.phone)
  );
  const [shift, setShift] = useState(props.profile.shift);
  const [team, setTeam] = useState(props.profile.team.name);
  const [vendorList, setVendorList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [allProjects, setAllProjects] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [client, setClient] = useState(null);
  const [consultant, setConsultant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [count, setCount] = useState(1);
  useEffect(() => {
    getClientList();
    getVendorList();
    getConsultantList();
    getSupportProjectsList();
    // eslint-disable-next-line
  }, []);

  const onChangeAll = (value, type) => {
    setCount(1);
    if (type === "client") {
      if (value === undefined) {
        setClient(null);
        getClientList();
        setSelectedProject(null);
        setAllProjects(null);
      } else {
        setClient(value);
      }
    } else if (type === "consultant") {
      if (value === undefined) {
        setConsultant(null);
        getConsultantList();
        setSelectedProject(null);
        setAllProjects(null);
      } else {
        setConsultant(value);
      }
    } else if (type === "vendor") {
      if (value === undefined) {
        setVendor(null);
        getVendorList();
        setSelectedProject(null);
        setAllProjects(null);
      } else {
        setVendor(value);
      }
    }
  };

  const getSupportProjectsList = () => {
    setLoading(true);
    axiosJSON
      .get("/api/employee/get_projects/")
      .then((res) => {
        setProjects(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        messageError(error);
        setLoading(false);
      });
  };

  const addSupport = () => {
    setLoading(true);
    if (startDate) {
      let body = {
        start:
          startDate && startDate !== ""
            ? moment(startDate).format("YYYY-MM-DD")
            : null,
        project: parseInt(selectedProject),
        support: parseInt(props.profile.id),
        status: "active",
      };
      axiosJSON
        .post("/api/project/" + selectedProject + "/support/", body)
        .then((res) => {
          getSupportProjectsList();
          setLoading(false);
          messageSuccess(res.data.message);
        })
        .catch((error) => {
          setLoading(false);
          messageError(error);
        });
      setAdd(false);
      setVendor(null);
      setClient(null);
      setConsultant(null);
      setStartDate(null);
      getClientList();
      getVendorList();
      getConsultantList();
      setSelectedProject(null);
    } else {
      messageError("Please Enter Support start date");
      setLoading(false);
    }
  };

  const onStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const onSearchAll = (value, type) => {
    if (type === "client") {
      getClientList(value);
    } else if (type === "consultant") {
      getConsultantList(value);
    } else if (type === "vendor") {
      getVendorList(value);
    }
  };

  const cancel = () => {
    setAdd(false);
    setClient(null);
    setConsultant(null);
    setVendor(null);
    setSelectedProject(null);
    getClientList();
    getVendorList();
    getConsultantList();
    setAllProjects(null);
    setCount(1);
  };

  const getSpecificProject = () => {
    setCount(0);
    setLoading(true);
    axiosJSON
      .get(
        `api/employee/projects/?vendor=${vendor}&client=${client}&consultant=${consultant}`
      )
      .then((res) => {
        setAllProjects(res.data.data);
        setCount(0);
        setLoading(false);
      })
      .catch(function (error) {
        setAllProjects(null);
        messageError(error);
        setCount(0);
        setLoading(false);
      });
  };

  const getVendorList = (params = "") => {
    setLoading(true);
    axiosJSON
      .get("/api/vendor_company/?query=" + params)
      .then((res) => {
        setVendorList(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        messageError(error);
        setLoading(false);
      });
  };

  const getConsultantList = (query = "") => {
    setLoading(true);
    axiosJSON
      .get("/api/consultant/?query=" + query)
      .then((res) => {
        setConsultantList(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        messageError(error);
        setLoading(false);
      });
  };

  const getClientList = (query = "") => {
    setLoading(true);
    axiosJSON
      .get("/api/submission/client/?query=" + query)
      .then((res) => {
        setClientList(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        messageError(error);
        setLoading(false);
      });
  };

  const getCurrentproject = (current_project) => {
    let allProject = "";
    let count = 0;
    current_project.map(({ id, name }) => {
      allProject += ` ${name}`;
      count += 1;
      if (current_project.length !== count) {
        allProject += ",";
      }
      return null;
    });
    return allProject;
  };

  const onChnageAll = (value, changeFor) => {
    props.chnageProfileBody(changeFor, value);
    if (changeFor === "employee_name") {
      setName(value);
    } else if (changeFor === "gender") {
      setGender(value);
    } else if (changeFor === "phone") {
      setPhoneNumber(value);
    } else if (changeFor === "gender") {
      setGender(value);
    } else if (changeFor === "shift") {
      setShift(value);
    } else if (changeFor === "team") {
      setTeam(value);
    } else if (changeFor === "technology") {
      setTechnology(value);
    }
  };

  return (
    <div className="tabular_ana">
      <div className="accordian_styles">
        <Spin spinning={loading}>
          <Collapse
            defaultActiveKey={["1", "2"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Personal Details" key="1">
              <div className="row mb-3">
                <div className="col-md-12 pt-1">
                  <div className="head1 mb-1">Name</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Input
                    allowClear
                    style={{ width: "80%" }}
                    placeholder="Please Add Your Name"
                    value={name}
                    onChange={(e) =>
                      onChnageAll(e.target.value, "employee_name")
                    }
                  />
                </div>
                <div className="col-md-12 pt-3">
                  <div className="head1 mb-1">Mail ID</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Input
                    allowClear
                    style={{ width: "80%" }}
                    value={props.profile.email}
                    disabled={true}
                  />
                </div>
                <div className="col-md-12 pt-3">
                  <div className="head1 mb-1">Contact Number</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <PhoneInput
                    country={"in"}
                    placeholder="Please add your contact information"
                    onlyCountries={["in", "us", "ca"]}
                    countryCodeEditable={false}
                    value={phoneNumber}
                    onChange={(e) => onChnageAll(e, "phone")}
                    inputClass="phoneNumberInput"
                    style={{ width: "80%" }}
                    disableCountryGuess={true}
                  />
                </div>
                <div className="col-md-12 pt-3">
                  <div className="head1 mb-1">Gender</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Radio.Group
                    style={{ width: "80%" }}
                    value={gender}
                    onChange={(e) => onChnageAll(e.target.value, "gender")}
                  >
                    <Radio key={1} value={"male"}>
                      Male
                    </Radio>
                    <Radio key={2} value={"female"}>
                      Female
                    </Radio>
                    <Radio key={3} value={"other"}>
                      Other
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="col-md-12 pt-3">
                  <div className="head1 mb-1">Shift Details</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Select
                    allowClear
                    style={{ width: "80%" }}
                    value={shift}
                    onChange={(e) => onChnageAll(e, "shift")}
                  >
                    {props.shiftList &&
                      props.shiftList.map((ele) => {
                        return (
                          <Select.Option key={ele.value} value={ele.value}>
                            {ele.display}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </Panel>
            <Panel header="Professional Details" key="2">
              <div className="row mb-3">
                <div className="col-md-12 pt-1">
                  <div className="head1 mb-1">Department</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Select
                    disabled={true}
                    allowClear
                    style={{ width: "80%" }}
                    placeholder="Please select department"
                    value={
                      props.profile.team && props.profile.team.department
                        ? props.profile.team.department
                        : "NA"
                    }
                  ></Select>
                </div>
                <div className="col-md-12 pt-3">
                  <div className="head1 mb-1">Team</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Select
                    allowClear
                    style={{ width: "80%" }}
                    placeholder="Please select Team"
                    value={team}
                    onChange={(e) => onChnageAll(e, "team")}
                  >
                    {props.teamList &&
                      props.teamList.map(({ id, name }) => (
                        <Select.Option key={id} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>

                <div className="col-md-12 pt-3">
                  <div className="head1 mb-1">Roles</div>
                </div>
                <div className="col-md-12 tag_coll">
                  <Select
                    disabled={true}
                    mode="multiple"
                    allowClear
                    style={{ width: "80%" }}
                    placeholder="Please select role"
                    value={props.profile.roles}
                  ></Select>
                </div>
                {props.profile.roles &&
                props.profile.roles.indexOf("engineer") > -1 ? (
                  <>
                    <div className="col-md-12 pt-3">
                      <div className="head1 mb-1">Skills</div>
                    </div>
                    <div className="col-md-12 tag_coll">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "80%" }}
                        placeholder="Please select skills"
                        value={technology}
                        onChange={(e) => onChnageAll(e, "technology")}
                      >
                        {props.technologyList.map((tech, i) => (
                          <Select.Option key={i} value={tech}>
                            {tech}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>

                    {technology && technology.includes("Other") && (
                      <>
                        <div className="col-md-12 pt-3">
                          <div className="head1 mb-1">
                            Other Skills
                            <span
                              style={{ marginLeft: "10px", fontSize: "14px" }}
                            >
                              <Tooltip
                                title={
                                  "Please enter Skills with Comma(,) sepration"
                                }
                              >
                                <InfoCircleFilled />
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 tag_coll">
                          <Input
                            style={{ width: "80%" }}
                            onChange={(e) =>
                              props.onOtherSkillChange(e.target.value)
                            }
                            value={props.otherSkill}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-md-12 pt-3">
                      <div className="head1 mb-1">Current Project</div>
                    </div>
                    <div className="col-md-12 tag_coll">
                      <Input
                        disabled={true}
                        style={{ width: "80%" }}
                        value={
                          props.profile.project
                            ? getCurrentproject(
                                props.profile.project.current_project
                              )
                            : "Not Available"
                        }
                      />
                    </div>

                    <div className="col-md-12 pt-3">
                      <div className="head1 mb-1">Total Project</div>
                    </div>
                    <div className="col-md-12 tag_coll">
                      <Input
                        disabled={true}
                        style={{ width: "80%" }}
                        value={
                          props.profile.project
                            ? props.profile.project.total
                            : "0"
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 p-0 row m-0 pt-3">
                      <div className="col-md-12">
                        <div className="head1 mb-1 check_head">
                          <Checkbox
                            className="pr-2"
                            checked={isRemote}
                            onChange={(e) => {
                              setIsRemote(e.target.checked);
                            }}
                          />
                          Are you supporting a remote project?
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {isRemote && (
                <>
                  <div className="border_curproj">
                    {projects.length > 0 && (
                      <div>
                        <p className="remote_sty">
                          All Current Projects are supporting
                        </p>
                        <div className="numbering">
                          {projects.map((ele, i) => (
                            <div key={i} className="numb_ser px-0 py-2">
                              {ele.client} :: {ele.consultant_name} ::{" "}
                              {ele.vendor}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div>
                      <div
                        className="plusAdd pt-2"
                        onClick={() => {
                          setAdd(true);
                        }}
                      >
                        <PlusOutlined className="pr-1" />
                        Add Another Project{" "}
                      </div>
                      {add && (
                        <div>
                          <div className="col-md-12 px-0 pt-3">
                            <div className="head1 mb-1 font-head">
                              Select Client
                            </div>
                          </div>
                          <div className="col-md-12 px-0 tag_coll">
                            <Select
                              allowClear
                              style={{ width: "40%" }}
                              placeholder="Select Client"
                              value={client}
                              onChange={(e) => onChangeAll(e, "client")}
                              onSearch={(e) => {
                                onSearchAll(e, "client");
                              }}
                              showSearch
                            >
                              {clientList.length > 0 &&
                                clientList.map((ele, i) => (
                                  <Select.Option key={i} value={ele}>
                                    {ele}
                                  </Select.Option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-md-12 px-0 pt-3">
                            <div className="head1 mb-1 font-head">
                              Select Consultant
                            </div>
                          </div>
                          <div className="col-md-12 px-0 tag_coll">
                            <Select
                              allowClear
                              style={{ width: "40%" }}
                              placeholder="Select Cosultant"
                              value={consultant}
                              onChange={(e) => {
                                onChangeAll(e, "consultant");
                              }}
                              onSearch={(e) => {
                                onSearchAll(e, "consultant");
                              }}
                              showSearch
                            >
                              {consultantList.length > 0 &&
                                consultantList.map((ele, i) => (
                                  <Select.Option key={i} value={ele.name}>
                                    {ele.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-md-12 px-0 pt-3">
                            <div className="head1 mb-1 font-head">
                              Select Vendor
                            </div>
                          </div>
                          <div className="col-md-12 px-0 tag_coll">
                            <Select
                              allowClear
                              style={{ width: "40%" }}
                              placeholder="Select Vendor"
                              value={vendor}
                              onSearch={(e) => {
                                onSearchAll(e, "vendor");
                              }}
                              onChange={(e) => onChangeAll(e, "vendor")}
                              showSearch
                            >
                              {vendorList.length > 0 &&
                                vendorList.map((ele, i) => (
                                  <Select.Option key={i} value={ele.name}>
                                    {ele.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </div>

                          {client && consultant && vendor && count === 1 && (
                            <>{getSpecificProject()}</>
                          )}
                        </div>
                      )}
                      {add && client && consultant && vendor && allProjects && (
                        <>
                          {allProjects.length > 0 ? (
                            <div>
                              <div className="col-12 px-0 pt-2">
                                Select Current Remote Projects You want to add
                              </div>
                              <div className="col-12 px-0 pt-2">
                                <Radio.Group
                                  onChange={(e) => {
                                    setSelectedProject(e.target.value);
                                  }}
                                  value={selectedProject}
                                >
                                  {allProjects.map((ele) => (
                                    <Radio key={ele.id} value={ele.id}>
                                      {ele.consultant_name} :: {ele.client} ::{" "}
                                      {ele.vendor}
                                    </Radio>
                                  ))}
                                </Radio.Group>
                              </div>
                            </div>
                          ) : (
                            <div className="pt-2">No project found</div>
                          )}
                        </>
                      )}
                      {add && selectedProject && (
                        <div className="col-12 px-0 pt-2">
                          <div className="col-md-4 px-0">
                            <span style={{ color: "red" }}>*</span>
                            <label className="pt-2">
                              Support Start Date :{" "}
                            </label>
                          </div>
                          <div className="col-md-8 px-0">
                            <DatePicker
                              onChange={(date, dateString) =>
                                onStartDateChange(date, dateString)
                              }
                              style={{ width: "250px" }}
                              format="MM-DD-YYYY"
                              value={
                                startDate !== null && startDate !== ""
                                  ? moment(startDate)
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      )}

                      {add && selectedProject && (
                        <div className="col-12 px-0 pt-4">
                          <Button
                            className={"normal_button f-no"}
                            onClick={() => addSupport()}
                          >
                            Submit Details
                          </Button>
                          <Button
                            className={"outline_button f-no"}
                            onClick={() => cancel()}
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 pt-3 px-0">
                    <div className="head1 mb-1">Skills</div>
                  </div>
                  <div className="col-md-12 px-0 tag_coll tag-collection">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "80%" }}
                      placeholder="Please select skills"
                      value={technology}
                      onChange={(e) => onChnageAll(e, "technology")}
                    >
                      {props.technologyList.map((tech, i) => (
                        <Select.Option key={i} value={tech}>
                          {tech}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  {technology && technology.includes("Other") && (
                    <>
                      <div className="col-md-12 pt-3">
                        <div className="head1 mb-1">
                          Other Skills
                          <span
                            style={{ marginLeft: "10px", fontSize: "14px" }}
                          >
                            <Tooltip
                              title={
                                "Please enter Skills with Comma(,) sepration"
                              }
                            >
                              <InfoCircleFilled />
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12 tag_coll">
                        <Input
                          style={{ width: "80%" }}
                          onChange={(e) =>
                            props.onOtherSkillChange(e.target.value)
                          }
                          value={props.otherSkill}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </Panel>
            {/* <Panel header="Certifications" key="3" extra={props.genExtra()}>
              <CertificationData
                certificatesList={props.certificatesList}
                edit={true}
                onCertificateEdit={props.onCertificateEdit}
              />
            </Panel> */}
          </Collapse>
        </Spin>
        <div className="row pt-3">
          <div className={"col-md-12"}>
            <Button className={"outline_button"} onClick={props.onCancel}>
              Cancel
            </Button>
            <Button
              className={"normal_button"}
              onClick={() => props.onUpdate()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserProfile;
