import React, {useEffect, useState} from "react";
import {Button, Form, Input, Spin, Alert} from "antd";
import IntlMessages from "util/IntlMessages";
import axiosJSON from "../util/Api";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import { messageError, messageSuccess } from "../util/AlertMessage";


const FormItem = Form.Item;


const ResetPassword = (props) => {

  const history = useHistory();
  const token = useSelector(({auth}) => auth.token);
  const [loading, setLoading] = useState(false)
  const [resetMessage, setResetMessage] = useState(null)

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(()=>{
    let message = localStorage.getItem('resetMessage')
    if(message){
      setResetMessage(message)
    }

  },[])

  useEffect(() => {
    if (token !== null) {
      props.history.push('/dashboard');
    }
  }, [token, props.history]);

  const onFinish = values => {
    setLoading(true)
    axiosJSON.post('api/password/confirm_password/', values)
      .then(({data, status}) => {
        if (status === 200) {
          messageSuccess(data.message)
          setLoading(false)
          history.push('/signin')
        }
      }).catch(function (error) {
      if(error.status === 400){
        setLoading(false)
        messageError(error.data.email.toString())
      }
      else{
        setLoading(false)
        messageError(error.data.email.toString())
      }
    })
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">

        <div className="gx-login-header" style={{background:'#24305e',borderRadius:'5px',margin:'-10px'}}>
          <img src={require("assets/images/logo-white.png")} alt="log1" title="log1"/>
        </div>
        <br/>
        <div className="gx-mb-4">
          <h2>Reset Password</h2>
          <p><IntlMessages id="appModule.enterResetToken"/></p>
        </div>
        {
          resetMessage && <Alert message={resetMessage} type={"success"}/>
        }

        <Spin spinning={loading}>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem rules={ [{required: true, message: 'Please enter correct token!'}]} name="token">
            <Input className='gx-input-lineheight' type="text" placeholder="Enter Token from email"/>
          </FormItem>

          <FormItem rules={ [{required: true, message: 'Please enter your password!'}]} name="password">
            <Input className='gx-input-lineheight' placeholder="Enter New Password" type="password"/>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.reset"/>
            </Button>
          </FormItem>
        </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ResetPassword;
