import React, {useEffect, useState} from "react";
import { Select} from "antd";
import axiosJSON from "../util/Api";
import {useDispatch} from "react-redux";
import {impersonatedMeDetails} from "../appRedux/actions";
import {CustomModal as Modal} from "../util/CustomModal"
import { messageError } from "../util/AlertMessage";

const Impersonation = (props) => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setUser] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getAllUserList()
  },[])

  const getAllUserList = () => {
    axiosJSON.get('/api/impersonate/users/')
      .then(({data}) => {
        setUserList(data.data)
      })
      .catch(error =>
        messageError(error)
      )
  }
  const handleChange = (value) => {
    setUser(value)
  }

  const onSubmit = () => {
    let user;
    userList.filter(e=>parseInt(e.id) === parseInt(selectedUser) ? user = e.id : null)
    const body={
      'id':parseInt(selectedUser)
    }
    axiosJSON.post('/api/impersonate/',body)
      .then(({data}) => {
        localStorage.setItem("current_token",data.data.token);
        localStorage.setItem("current_id",user);
        axiosJSON.defaults.headers['authorization'] = "Token " + data.data.token;
        dispatch(impersonatedMeDetails(data.data.token));
        props.openImpersonate(false);
      })
      .catch(error =>
        messageError(error)
      )
  }

  return (
    <Modal
      title="Impersonate User"
      onCancel={() => props.openImpersonate(false)}
      visible={props.impersonateButton}
      onOk={onSubmit}
    >
      <Select
        showSearch
        notFoundContent={null}
        value={selectedUser}
        onChange={handleChange}
        style={{width: '200px'}}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          userList.length > 0 && userList.map((user, i) =>
            <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)
        }
      </Select>
    </Modal>
  );
};

export default Impersonation;
