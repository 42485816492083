import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  MY,
  FETCHING,
  UNREAD_COUNTER,
  FITLERS,
  SEARCH,
  QUERY,
  FILTER_FOR,
  CURRENT_PAGE,
  CURRENT_SIZE,
  ASSIGNMENT_STATUS

} from 'constants/ActionTypes';
import { LOAD_DATA } from '../../constants/ActionTypes';
import { HEAD_DATA } from '../../constants/ActionTypes';

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  my: true,
  query: '',
  fetching: true,
  unread_notify: 0,
  filters: {},
  search:'',
  filter_for:'all',
  current_page: 1,
  current_size: 10,
  headerData:null,
  engineerData:null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case MY: {
      return {...state, my: action.payload};
    }
    case QUERY: {
      return {...state, query: action.payload};
    }
    case FETCHING: {
      return {...state, fetching: false};
    }
    case FITLERS: {
      return {...state, filters: action.payload};
    }
    case SEARCH: {
      return {...state, search: action.payload};
    }
    case FILTER_FOR: {
      return {...state, filter_for: action.payload};
    }
    case ASSIGNMENT_STATUS: {
      return {...state, assignment_status: action.payload};
    }
    case UNREAD_COUNTER: {
      if (action.payload !== undefined) {
        return {...state, unread_notify: action.payload};
      } else {
        return {...state};
      }
    }
    case CURRENT_PAGE: {
      return {...state, current_page: action.payload};
    }
    case CURRENT_SIZE: {
      return {...state, current_size: action.payload};
    }
    case LOAD_DATA :{
      return {...state, headerData: action.payload }
    }
    case HEAD_DATA :{
      return {...state, engineerData: action.payload }
    }
    default:
      return state;
  }
}
