import React from "react";
import { Badge, Avatar } from "antd";
import axiosJSON from "util/Api";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { unreadNotify } from "../../appRedux/actions";
import { useDispatch } from "react-redux";
import { messageError, messageInfo } from "../../util/AlertMessage";

const NotificationAvatar = (props) => {
  return (
    <Avatar className="gx-size-40 gx-mr-3">
      {props.notification.avatar.split(" ").length > 1
        ? props.notification.avatar.split(" ")[0].charAt(0).toUpperCase() +
          props.notification.avatar.split(" ")[1].charAt(0).toUpperCase()
        : props.notification.avatar.split(" ")[0].charAt(0).toUpperCase()}
    </Avatar>
  );
};

const dateFormat = "DD-MMM-YYYY, hh:mm A";
const NotificationItem = (props) => {
  const notf = props.notification;
  const dispatch = useDispatch();
  const { description, title, timestamp } = notf;
  const markAsRead = () => {
    if (props.is_read) {
      axiosJSON
        .get("api/emp_notify/" + props.id + "/mark_as_read/")
        .then((res) => {
          let token = localStorage.getItem("token");
          let currentToken = localStorage.getItem("current_token");
          if (currentToken) {
            dispatch(unreadNotify(currentToken));
            props.get(currentToken, props.page);
          } else {
            dispatch(unreadNotify(token));
            props.get(token, props.page);
          }
        })
        .catch(function (error) {
          messageError(error.message);
        });
    }
    let target = notf.target;
    if (target.name === "consultant") {
      if (target.sub_name === "feedback") {
        props.history.push("/consultant/bench/" + target.id + "/?key=feedback");
      } else if (target.sub_name === "comment") {
        props.history.push("/consultant/bench/" + target.id + "/?key=comments");
      } else if (target.sub_name === "consultantexit") {
        props.history.push(
          "/consultant/bench/" + target.id + "/?key=exit_details"
        );
      } else if (target.sub_name === "consultantraterevision") {
        props.history.push(
          "/consultant/bench/" + target.id + "/?key=rate-revision"
        );
      } else if (target.sub_name === "consultantmarketing") {
        props.history.push(
          "/consultant/bench/" + target.id + "/?key=marketing"
        );
      } else {
        props.history.push("/consultant/bench/" + target.id + "/?key=summary");
      }
    } else if (target.name === "submission") {
      if (target.sub_name === null) {
        localStorage.setItem("key", "submission");
        props.history.push("/details/" + target.id + "/support");
      }
      if (target.sub_name === "projectsupport") {
        localStorage.setItem("key", "po");
        props.history.push("/details/" + target.id + "/support");
      } else if (target.sub_name === "project") {
        localStorage.setItem("key", "po");
        props.history.push(
          "/details/" +
            target.id +
            "/" +
            target.sub_name +
            "?id=" +
            target.sub_id
        );
      } else {
        localStorage.setItem("key", target.sub_name);
        props.history.push(
          "/details/" +
            target.id +
            "/" +
            target.sub_name +
            "?id=" +
            target.sub_id
        );
      }
    } else {
      messageInfo("This notification cannot be redirected.");
    }
    props.hide();
  };

  return (
    <li style={{ cursor: "pointer" }} className="gx-media" onClick={markAsRead}>
      {!props.is_read ? (
        <NotificationAvatar notification={props.notification} />
      ) : (
        <Badge dot>
          <NotificationAvatar notification={props.notification} />
        </Badge>
      )}
      <div className="gx-media-body gx-align-self-center">
        <p className="gx-fs-sm gx-mb-0">{title}</p>
        <p className="gx-fs-sm gx-mb-0">{description}</p>
        <span className="gx-meta-date">
          <small>{moment(timestamp).format(dateFormat)}</small>
        </span>
      </div>
    </li>
  );
};

export default withRouter(NotificationItem);
