import axios from "axios";
import { messageError } from "./AlertMessage";

// export const BASE_URL = "https://api.log1.com"; //prod
// export const BASE_URL = "https://staging.log1.com"; //staging
// export const BASE_URL = "http://65.0.105.55:8000/"; //dev
// export const BASE_URL = "http://localhost:8000/"; //local
export const BASE_URL = "https://liteapi.log1.com"; // lite

// export const DASHBOARD_URL = "http://localhost:8000/add"//local
// export const DASHBOARD_URL = "https://bta.consultadd.com/add"; //prod
export const DASHBOARD_URL = "https://bta.log1.com/add"; //staging

export let axiosJSON;

axiosJSON = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosJSON.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      delete axiosJSON.defaults.headers["authorization"];
      localStorage.removeItem("token");
      localStorage.removeItem("current_token");
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      window.location.reload();
    } else if (error.response.status >= 400 && error.response.status < 500) {
      if (error.response.data.hasOwnProperty("message")) {
        return Promise.reject(error.response.data.message);
      } else {
        return Promise.reject(error.response.data.error);
      }
    } else if (error.response.status === 500) {
      messageError("Server Error");
    }
  }
);

export default axiosJSON;
