import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import AddUser from "../../modals/addUser";
import EditProfile from "../../modals/editProfile";
import {CustomModal as Modal} from "../../util/CustomModal"

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.authUser);
  const [visible, setVisible] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li className="user_mob">{user.employee_name}</li>
      <li
        onClick={() => {
          setEditProfile(true);
          setVisible(false);
        }}
      >
        Profile
      </li>
      {user && user.roles.includes("superadmin") ? (
        <li
          onClick={() => {
            setAddUser(true);
            setVisible(false);
          }}
        >
          Add User
        </li>
      ) : null}

      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  const handleClose = () => {
    setAddUser(false);
    setEditProfile(false);
  };


  const onRevrt = (link) =>{
    setEditProfile(false);
    window.location.href = link;
  }

  return (
    user && (
      <div className="gx-flex-row gx-align-items-center gx-mb-3 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
          visible={visible}
          onVisibleChange={(visible) => setVisible(visible)}
        >
          {/* {user.avatar === null &&
          user.employee_name.split(" ").length !== 0 ? (
            user.employee_name.split(" ").length >= 2 ? (
              <Avatar className="gx-size-30 gx-pointer gx-mr-2">
                {user.employee_name.split(" ")[0].charAt(0).toUpperCase() +
                  user.employee_name.split(" ")[1].charAt(0).toUpperCase()}
              </Avatar>
            ) : (
              <Avatar className="gx-size-30 gx-pointer gx-mr-2">
                {user.employee_name.split(" ")[0].charAt(0).toUpperCase()}
              </Avatar>
            )
          ) : (
            <Avatar
              src={ user.avatar}
              className="gx-size-30 gx-pointer gx-mr-2"
              alt=""
            />
          )} */}
          {Object.entries(user).length > 0 && (
            <Avatar
              className="gx-size-30 gx-pointer gx-mr-2"
              style={{ color: "#555F8B", backgroundColor: "#F1F3FA" }}
              src={user.avatar}
            >
              {user.employee_name.split(" ")[1]
                ? user.employee_name.split(" ")[0].charAt(0).toUpperCase() +
                  user.employee_name.split(" ")[1].charAt(0).toUpperCase()
                : user.employee_name.split(" ")[0].charAt(0).toUpperCase()}
            </Avatar>
          )}
          <span className="gx-avatar-name">
            {user.employee_name}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />{" "}
          </span>
        </Popover>
        {editProfile && (
          <Modal
            title="Profile"
            onCancel={handleClose}
            visible={editProfile}
            footer={null}
            className="edit_model"
          >
            <EditProfile handleClose={handleClose} onRevert={onRevrt} />
          </Modal>
        )}
        {addUser && (
          <Modal
            title="Add User"
            onCancel={handleClose}
            visible={addUser}
            footer={null}
          >
            <AddUser handleClose={handleClose} />
          </Modal>
        )}
      </div>
    )
  );
};

export default UserProfile;
