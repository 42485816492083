// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Common Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const MY = 'MY';
export const QUERY = 'QUERY';
export const FETCHING = 'FETCHING';
export const UNREAD_COUNTER = 'UNREAD_COUNTER';
export const FITLERS = 'FITLERS';
export const SEARCH = 'SEARCH';
export const FILTER_FOR = 'FILTER_FOR';
export const ASSIGNMENT_STATUS = 'ASSIGNMENT_STATUS';
export const LOAD_DATA = 'LOAD_DATA';
export const HEAD_DATA = 'HEAD_DATA';

//Auth const
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER = 'USER';
export const NEW_USER = 'NEW_USER';
export const USER_ACCESS = 'USER_ACCESS';
export const IMPERSONATE = 'IMPERSONATE';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

//Consultant
export const CON_STATUS = 'CON_STATUS';
export const CON_SUB_STATUS = 'CON_SUB_STATUS';

//Marketing
export const TEST_DETAILS = 'TEST_DETAILS';
export const INT_DETAILS = 'INT_DETAILS';
export const PO_DETAILS = 'PO_DETAILS';
export const SUB_DETAILS = 'SUB_DETAILS';
export const SUPPORT_DETAILS = 'SUPPORT_DETAILS';
export const LEAD_DETAILS = 'LEAD_DETAILS';
export const MARKETING_DATA = 'MARKETING_DATA';
export const COMMENT_DETAILS = 'COMMENT_DETAILS';
export const SUB_ID = 'SUB_ID';


// TIMESHEET

export const CURRENT_PAGE = 'CURRENT_PAGE';
export const CURRENT_SIZE = 'CURRENT_SIZE';

